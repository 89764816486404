<template>
    <div class="StatisticsDetail">
        <main-header menuActive="statistics" />

        <div id="contentsWrap">
            <div class="container sub">
                <div class="contentHead">
                    <h2> {{ title }} 통계 상세 현황 (검색일 : {{ date }})</h2>
                </div>

                <div class="row">
                    <div class="table-responsive">
                        <table class="table table-striped table-hover">
                            <thead>
                            <tr>
                                <th>연번</th>
                                <th>접수일</th>
                                <th>접수일 연번</th>
                                <th>확진일</th>
                                <th>지역</th>
                                <th>이름</th>
                                <th>성별</th>
                                <th>나이</th>
                                <th>생년월일</th>
                                <th>배정예정</th>
                                <th>시설명</th>
                                <th>배정일</th>
                                <th>배정 담당자</th>
                                <th>이송 완료</th>
                            </tr>
                            </thead>
                            <tbody v-if="dataList.length>0">
                            <tr v-for="(data, index) in dataList" :key="index" :style="'background-color:'+data.f_check_color+' !important'">
                                <td>{{ data.fIdx }}</td>
                                <td>{{ (data.fregDate != '0000-00-00 00:00:00') ? $moment(data.fregDate).format('YYYY-MM-DD') : '' }}</td>
                                <td>{{ data.fnum }}</td>
                                <td>{{ (data.fconfirmDate != '0000-00-00 00:00:00') ? $moment(data.fconfirmDate).format('YYYY-MM-DD') : '' }}</td>
                                <td>{{ data.fregion }}</td>
                                <td><a @click="popupPatientFunc(data)" class="text-link">{{ data.fpatientName }}</a></td>
                                <td>{{ (data.fpatientSex)  ? data.fpatientSex : '미입력'  }}</td>
                                <td>{{ data.fpatientAge }}</td>
                                <td>{{ (data.fpatientBirth != '0000-00-00 00:00:00') ? $moment(data.fpatientBirth).format('YYYY-MM-DD') : '' }}</td>
                                <td>{{ data.fassignTypeCode }}</td>
                                <td>{{ data.ffasilityCode }}</td>
                                <td>{{ (data.fassignDate != '0000-00-00 00:00:00') ? $moment(data.fassignDate).format('YYYY-MM-DD') : '' }}</td>
                                <td>{{ data.fmanagerCode }}</td>
                                <td>{{ data.fassignStateCode }}</td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr>
                                <td colspan="14">검색결과가 존재 하지 않습니다.</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="row text-center no-pdd-top">
                    <div class="pull-left">
                        <button type="button" class="btn btn-lg btn-default" @click="listBackFunc()">목록</button>
                    </div>
                </div>
            </div>
        </div>
        <main-footer />

        <!-- 환자정보 팝업 -->
        <popup-patient ref="popupPatient" />
    </div>
</template>

<script>
import mainHeader from '@/components/MainHeader.vue';
import mainFooter from '@/components/MainFooter.vue';
import PopupPatient from "@/components/PopupPatient";

export default {
    name: 'StatisticsDetail',
    data() {
        return {
            title:'',
            date:'',
            idx:'',
            sch_type:'',
            sch_select_type:'',
            dataList:[],
        }
    },
    components: { PopupPatient, mainHeader, mainFooter },
    // 계산된 속성
    computed: {
    },
    // 감시자
    watch: {
    },
    // $el 생성전
    created() {
        this.checkLogin();
    },
    // $el 생성후
    mounted() {
        this.$nextTick(function() {
            if(!this.storageGet('pageInfo')) this.storageSet('pageInfo', this.$route.params.data);

            let info = this.storageGet('pageInfo');
            this.title = info.title;
            this.date = info.date;
            this.idx = info.idx;
            this.sch_type = info.sch_type;
            this.sch_select_type = info.sch_select_type;
            this.searchListFunc(info);
        });
    },
    methods: {
        // 리스트 검색
        searchListFunc(info){
            let params = `{"regDt": "${info.date}", "schtype": "${info.sch_type}", "selectType": "${info.sch_select_type}", "code": "${info.idx}"}`;
            console.log(params);
            let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
            this.ajaxGet('nocStatistics/detail?params=' + queryString, null, 'searchListFuncResult');

            /*
            let formData = new FormData();
            formData.append('api_name', 'statisticsCommonDetail');                    // API 이름
            formData.append('sch_date', this.date);
            formData.append('sch_code', this.idx);
            formData.append('sch_type', this.sch_type);
            formData.append('sch_select_type', this.sch_select_type);

            this.ajaxPost('', formData,'searchListFuncResult');
            */
        },
        searchListFuncResult(response) {
            let result = response.list;
            this.dataList = result;
        },
        // 환자정보 변경
        popupPatientFunc(obj){
            obj.f_idx = obj.fIdx;
            console.log(obj);
            this.$refs.popupPatient.init(true, obj)
        },
        listBackFunc(){
            if (this.sch_type == 'symptom'){
                this.$router.push({ name: 'AdminStatisticsSeverity', params: this.storageGet('pageInfo')})
            } else if (this.sch_type == 'region'){
                this.$router.push({ name: 'AdminStatisticsArea', params: this.storageGet('pageInfo')})
            } else if (this.sch_type == 'unassign'){
                this.$router.push({ name: 'AdminStatisticsUnassign', params: this.storageGet('pageInfo')})
            } else if (this.sch_type == 'assign'){
                this.$router.push({ name: 'AdminStatisticsDateassign', params: this.storageGet('pageInfo')})
            } else if (this.sch_type == 'safecare'){
                this.$router.push({ name: 'AdminStatisticsSelfcare', params: this.storageGet('pageInfo')})
            } else {
                this.$router.push({ name: 'AdminStatisticsFacility', params: this.storageGet('pageInfo')})
            }

        },
    },
    destroyed(){
        this.storageRemove('pageInfo');
    }
}
</script>

<style scoped>
</style>
